import "./index.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const Header = () => {
    const history = useHistory();
    const [pesquisa, setPesquisa] = useState(false);

    const handlePesquisa = (e) => {
        e.preventDefault();
        let formdata = new FormData(e.target);
        history.push("/equipamentos/?pesquisa=" + formdata.get("pesquisa"));
        e.target.reset();
        setPesquisa(false);
    }

    return (
        <div id="header">
            <div className={`pesquisa ${pesquisa ? "active" : ""}`}>
                <div className="form">
                    <form onSubmit={handlePesquisa}>
                        <div className="inputs">
                            <label htmlFor="pesquisa">Pesquisar</label>
                            <input type="text" name="pesquisa" id="pesquisa" required />
                            <button type="submit" title="pesquisar"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/busca.svg`} alt="pesquisa" /></button>
                            <span onClick={() => setPesquisa(false)}>Voltar ao site</span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="container">
                <div className="wrapper">
                    <Link to="/" className="logo">
                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/logo.svg`} />
                    </Link>
                    <img onClick={() => document.getElementById("menu").classList.toggle("active")} className="responsive" src={`${localStorage.getItem("apiUrl")}imagens/medium/menu.svg`} />
                    <div className="menu" id="menu">
                        <img onClick={() => document.getElementById("menu").classList.toggle("active")} className="responsive" src={`${localStorage.getItem("apiUrl")}imagens/medium/close.svg`} />
                        <nav>
                            <Link className="p2 txt-dark" to="/">Home</Link>
                            <Link className="p2 txt-dark" to="/empresa">Sobre nós</Link>
                            <Link className="p2 txt-dark" to="/equipamentos">Equipamentos</Link>
                            <div className="socials">
                                <a href="#" title="Broto" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/broto.svg`} /></a>
                                <a href="#" title="Facebook" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/facebook.svg`} /></a>
                                <a href="#" title="Instagram" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/instagram.svg`} /></a>
                                <button type="button" onClick={() => setPesquisa(true)}><img src={`${localStorage.getItem("apiUrl")}imagens/medium/busca.svg`} /></button>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default Header;



