import "./index.scss";
import { useContext } from "react";
import Context from "../../Context";
import { Link } from "react-router-dom";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <div className="nav">
                    <div className="socials">
                        <Link to="/" className="logo">
                            <img src={`${localStorage.getItem("apiUrl")}imagens/medium/logo_w.svg`} />
                        </Link>
                        <a href="#" title="Broto" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/broto.webp`} /></a>
                        <a href="#" title="Facebook" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/facebook.webp`} /></a>
                        <a href="#" title="Instagram" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem("apiUrl")}imagens/medium/instagram.webp`} /></a>
                    </div>
                    <nav className="hidden-xs">
                        <Link className="p2 txt-dark" to="/">Home</Link>
                        <Link className="p2 txt-dark" to="/empresa">Sobre nós</Link>
                        <Link className="p2 txt-dark" to="/equipamentos">Equipamentos</Link>
                        {resumo.posts && resumo.posts.length > 0 && <Link className="p2 txt-dark" to="/blog">Blog</Link>}
                    </nav>
                </div>
                <div className="creditos">
                    <p className="p3">{resumo.configuracoes.app_title} - CNPJ: {resumo.configuracoes.cnpj} - Todos os direitos Reservados © {new Date().getFullYear()}</p>
                    <a className="p3" href="https://fw2propaganda.com.br/" title="Desenvolvido por Gustavo Barbosa">FW2 Propaganda</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;