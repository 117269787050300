import axios from 'axios';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { callForMetaUpdate } from "../../Utils/SEOHelper";

function SEOHelper({ history }) {
    const [metas, setMetas] = useState(false);

    useEffect(() => {
        getMetas(window.location.pathname.replaceAll("/", "-"));
        const unlisten = history.listen(() => {
            getMetas(window.location.pathname.replaceAll("/", "-"));
        });
        return () => {
            unlisten();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        let mts = metas && metas.titulo ? metas : window.defaultMetas;
        callForMetaUpdate(mts);
    }, [metas])

    const getMetas = (slug) => {
        let url = `${localStorage.getItem("apiUrl")}v2/seo/${slug}`;
        axios.get(url).then(response => {
            if (response.data.status === 200) {
                let info = response.data.success;
                let mts = {
                    "app_title": info.app_title ? info.app_title : window.defaultMetas.app_title,
                    "titulo": info.title ? info.title : window.defaultMetas.titulo,
                    "descricao": info.description ? info.description : window.defaultMetas.descricao,
                    "imagem": info.imagem && info.imagem.generic ? `${info.imagem.generic}.jpg?size=1200` : window.defaultMetas.imagem,
                    "keywords": info.keywords ? info.keywords : window.defaultMetas.keywords,
                    "width": info.width ? info.width : window.defaultMetas.width,
                    "height": info.height ? info.height : window.defaultMetas.height,
                    "type": info.type ? info.type : window.defaultMetas.type,
                    "card": info.summary ? info.summary : window.defaultMetas.summary,
                    "twitter": ""
                }

                setMetas(mts);
            } else {
                setMetas(window.defaultMetas);
            }
        });
    }

    return (null);
}

export default withRouter(SEOHelper);